import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

import Section from '../../components/Section';
import Button from '../../components/Button';

import MonitorWindow from '../../assets/svg/monitor-window.svg';
import metaValues from '../../../config';
import translation from '../../translate';

import styles from './styles.module.scss';

const scrollOption = {
  duration: 100,
  delay: 10,
  smooth: true
};

const scroll = Scroll.animateScroll;

const HowWeWork = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <article itemScope itemType="http://schema.org/NewsArticle">
      <section className={styles.section}>
        <Helmet>
          <title>
            {translation('_HWORK1', language)} - {defaultTitle}
          </title>
          <meta
            name="description"
            content="Our team of experienced React
        Engineers will bring your project a wealth of institutional
        knowledge about React, based on our real-world experience building
        a variety of applications, from MVP to complex enterprise-grade
        apps."
          />
          <meta property="og:title" content={defaultTitle} />
          <meta property="og:image" content="/public/react-application.png" />
        </Helmet>
        <Section className={styles.howWorkPromo} />
        <Section className={styles.howWork} itemprop="articleBody">
          <div className={styles.content}>
            <div className={styles.grid}>
              <div className={styles.gridOne}>
                <h1>{translation('_HWORK1', language)}</h1>
                <p>{translation('_HWORK2', language)}</p>
                <h2>{translation('_HWORK3', language)}</h2>
                <ul>
                  <li>{translation('_HWORK31', language)}</li>
                  <li>{translation('_HWORK32', language)}</li>
                  <li>{translation('_HWORK34', language)}</li>
                  <li>{translation('_HWORK35', language)}</li>
                  <li>{translation('_HWORK36', language)}</li>
                  <li>{translation('_HWORK37', language)}</li>
                </ul>

                <h2>{translation('_HWORK4', language)}</h2>
                <p>{translation('_HWORK5', language)}</p>
              </div>
              <div className={styles.gridTwo}>
                <img src={MonitorWindow} alt="Transparency" />
              </div>
            </div>
          </div>
        </Section>

        <Section className={styles.coding} fullwidth>
          <div className={styles.overlay} />
          <div className={styles.content}>
            <h1>{translation('_HWORK6', language)}</h1>
            <Link to={`/${language}/expertise`}>
              <Button
                size="large"
                onClick={() => scroll.scrollToTop(scrollOption)}
              >
                Show me
              </Button>
            </Link>
          </div>
        </Section>

        <Section className={styles.sand}>
          <div className={styles.grid}>
            <div className={styles.gridOne}>
              <h3>FRONT-END:</h3>
              <ul>
                <li>Adaptive/Responsive</li>
                <li>JWT authorization </li>
                <li>React/Redux flow</li>
                <li>Flow/Typescript typings</li>
                <li>ESLint/TSLint</li>
                <li>Airbnb/Prettier codestyle</li>
                <li>SASS/LESS support for css styles</li>
                <li>Babel transpiling</li>
                <li>SVG inline and animation support</li>
                <li>Formik for forms and validation of complex input forms</li>
                <li>JEST/Enzyme testing</li>
                <li>Axios for HTTP/HTTPS requests to backend</li>
                <li>Sentry as Bug/error reporting</li>
              </ul>
            </div>
            <div className={styles.gridTwo}>
              <h3>BACK-END:</h3>
              <ul>
                <li>Node.js/Express framework</li>
                <li>Mongo/SQL/PostgreSQL (Sequelize)</li>
                <li>Docker for integration tests for all DB logic</li>
                <li>Mocha, Chai, Jest for unit/integration tests</li>
                <li>Sentry as Bug/error reporting</li>
                <li>Passport Auth (Facebook, Google, Twitter and etc)</li>
                <li>ESLint code linter</li>
              </ul>
            </div>
          </div>
        </Section>
      </section>
    </article>
  );
};

export default memo(HowWeWork);
